.wrap {
  max-width: 1400px;
  margin: auto; }

.btn-group span {
  line-height: 31px;
  font-size: .8em; }

.brand-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #677f9E !important;
  font-size: .8em; }
  .brand-link:hover {
    text-decoration: none;
    color: #666666 !important;
    cursor: pointer; }

.btn-brand {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none; }
  .btn-brand:hover {
    background: #666666; }

.btn-close {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 25px;
  background: #272d38;
  color: white;
  border: none;
  border-radius: 100%;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px !important; }

.btn-select {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none;
  background: white;
  color: #677f9E; }
  .btn-select:hover {
    background: #666666; }
  .btn-select:hover {
    background: #666666;
    color: white; }
  .btn-select.active {
    background: #677f9E;
    color: white; }

.rdw-editor-toolbar {
  border-radius: 0;
  border-bottom: none;
  background: #FAFAFA; }

.rdw-dropdown-wrapper, .rdw-option-wrapper {
  background: #FAFAFA; }

@keyframes infinite-shift-left {
  0% {
    background-position: -50000px; }
  100% {
    background-position: 0; } }

.center-form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px;
  max-width: 340px; }
  .center-form input {
    width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .center-form .Select-control {
    margin: 4px 0; }
  .center-form input[type=submit] {
    margin-top: 10px; }
  .center-form .alert {
    display: block;
    margin: 10px 0; }
  .center-form p {
    margin: 10px 0;
    text-align: center; }

.content {
  position: relative;
  padding-top: 20px;
  padding-bottom: 55px;
  min-height: 300px; }
  @media screen and (min-width: 768px) {
    .content {
      padding-top: 125px; } }
  .content > ul {
    margin: 0;
    padding: 0; }
    .content > ul > li {
      margin: 0 0 20px;
      list-style: none; }
      .content > ul > li > a:hover {
        color: #677f9E;
        text-decoration: none; }
      .content > ul > li ul {
        margin: inherit;
        padding: 0; }
  .content .loading {
    margin-top: 50px; }
  .content .back-btn {
    margin: 20px 0;
    padding: 0; }
    .content .back-btn .fa {
      display: inline-block;
      font-size: 1.3em;
      padding-right: 5px; }
  .content .summary {
    width: 100%;
    margin: 0 auto 40px; }
    .content .summary .summary-info {
      font-size: .8em; }
    .content .summary .artist + .summary-info {
      margin-top: 5px; }
    .content .summary .tags {
      font-size: .8em; }
    .content .summary .details-button {
      padding-left: 0;
      padding-right: 0;
      margin: 25px 0; }
  .content .image {
    padding: 0;
    margin: 15px 0;
    height: 400px;
    text-align: right; }
    .content .image img {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 95%;
      max-height: 95%;
      cursor: pointer; }
  .content .btn-group {
    display: none; }
  .content .details {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #707070; }
    .content .details .images {
      display: none; }
    .content .details > .wrap {
      max-width: 768px;
      margin: auto; }
      @media screen and (min-width: 1200px) {
        .content .details > .wrap {
          position: absolute;
          top: 50%;
          transform: translateY(-50%); } }
    .content .details > div, .content .details > ul {
      justify-content: left; }
      .content .details > div .label, .content .details > ul .label {
        font-size: .8em;
        font-style: italic;
        color: black; }
      .content .details > div .details span, .content .details > ul .details span {
        display: block;
        text-transform: capitalize; }
    .content .details .artist {
      color: #677f9E;
      font-weight: bold;
      margin: 0 0 3px; }
    .content .details .title {
      font-style: italic; }
    .content .details .images {
      margin: 20px 0; }
      .content .details .images ul {
        padding: 0; }
        .content .details .images ul li {
          position: relative;
          display: inline-block;
          width: 50px;
          height: 50px;
          list-style: none;
          margin: 0 8px 8px 0; }
          .content .details .images ul li:first-child {
            display: none; }
          .content .details .images ul li button:focus {
            outline: none; }
          .content .details .images ul li button img {
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 95%;
            max-height: 95%;
            cursor: pointer; }
  @media screen and (min-width: 1200px) {
    .content .shrink .summary {
      position: fixed;
      z-index: 1;
      top: 121px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      background: white; }
      .content .shrink .summary .image {
        height: 0;
        min-height: 0;
        flex: .3; }
      .content .shrink .summary .details {
        opacity: 0; }
        .content .shrink .summary .details > .wrap > div {
          display: none; }
          .content .shrink .summary .details > .wrap > div.location, .content .shrink .summary .details > .wrap > div.artist {
            display: block; }
          .content .shrink .summary .details > .wrap > div.summary-info {
            display: block;
            margin: 0;
            font-size: inherit; }
            .content .shrink .summary .details > .wrap > div.summary-info > div {
              display: none; }
              .content .shrink .summary .details > .wrap > div.summary-info > div.location, .content .shrink .summary .details > .wrap > div.summary-info > div.title-year {
                display: block; }
          .content .shrink .summary .details > .wrap > div.btn-group {
            display: block; }
    .content .shrink.fixed .summary .image {
      height: 200px;
      transition: all 150ms ease; }
    .content .shrink.fixed .summary .details {
      opacity: 1; } }
