.home-carousel {
  display: none; }
  @media screen and (min-width: 768px) {
    .home-carousel {
      display: block;
      flex: 1;
      width: 100%;
      height: 402px;
      margin-top: 20px;
      background: transparent url(/assets/theme/logo.png) repeat-x 50% 0;
      animation: infinite-shift-left 1000s linear infinite; } }

.login-screen {
  width: 100%;
  flex: 1;
  align-self: flex-end;
  text-align: center; }
  .login-screen a {
    display: block;
    margin-top: 15px; }
