.wrap {
  max-width: 1400px;
  margin: auto; }

.btn-group span {
  line-height: 31px;
  font-size: .8em; }

.brand-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #677f9E !important;
  font-size: .8em; }
  .brand-link:hover {
    text-decoration: none;
    color: #666666 !important;
    cursor: pointer; }

.btn-brand {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none; }
  .btn-brand:hover {
    background: #666666; }

.btn-close {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 25px;
  background: #272d38;
  color: white;
  border: none;
  border-radius: 100%;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px !important; }

.btn-select {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none;
  background: white;
  color: #677f9E; }
  .btn-select:hover {
    background: #666666; }
  .btn-select:hover {
    background: #666666;
    color: white; }
  .btn-select.active {
    background: #677f9E;
    color: white; }

.rdw-editor-toolbar {
  border-radius: 0;
  border-bottom: none;
  background: #FAFAFA; }

.rdw-dropdown-wrapper, .rdw-option-wrapper {
  background: #FAFAFA; }

@keyframes infinite-shift-left {
  0% {
    background-position: -50000px; }
  100% {
    background-position: 0; } }

.center-form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px;
  max-width: 340px; }
  .center-form input {
    width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .center-form .Select-control {
    margin: 4px 0; }
  .center-form input[type=submit] {
    margin-top: 10px; }
  .center-form .alert {
    display: block;
    margin: 10px 0; }
  .center-form p {
    margin: 10px 0;
    text-align: center; }

#modal-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  padding: 30px;
  background: white;
  text-align: center; }
  #modal-window .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1; }
  #modal-window .title {
    margin: 30px 0 0; }
  #modal-window .slick-slider {
    height: 95%; }
    #modal-window .slick-slider .slick-arrow {
      overflow: visible;
      right: -20px;
      width: auto;
      height: auto;
      padding: 5px; }
      #modal-window .slick-slider .slick-arrow.slick-prev {
        left: -20px;
        right: auto; }
      #modal-window .slick-slider .slick-arrow:before {
        color: #677f9E;
        font-size: 30px; }
        #modal-window .slick-slider .slick-arrow:before:hover {
          color: #272d38; }
    #modal-window .slick-slider .slick-list {
      height: 100%; }
      #modal-window .slick-slider .slick-list .slick-track {
        height: 100%; }
        #modal-window .slick-slider .slick-list .slick-track > div {
          position: relative;
          height: 100%; }
          #modal-window .slick-slider .slick-list .slick-track > div .zoom-container {
            position: relative;
            width: 80%;
            height: auto;
            margin: auto;
            top: 50%;
            transform: translateY(-50%); }
            #modal-window .slick-slider .slick-list .slick-track > div .zoom-container > div {
              overflow: hidden; }
              #modal-window .slick-slider .slick-list .slick-track > div .zoom-container > div > div {
                margin: auto; }
          #modal-window .slick-slider .slick-list .slick-track > div .type {
            position: absolute;
            bottom: -50px;
            text-align: center;
            width: 100%; }
    #modal-window .slick-slider .slick-dots {
      bottom: -15px; }
