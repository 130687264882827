.wrap {
  max-width: 1400px;
  margin: auto; }

.btn-group span {
  line-height: 31px;
  font-size: .8em; }

.brand-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #677f9E !important;
  font-size: .8em; }
  .brand-link:hover {
    text-decoration: none;
    color: #666666 !important;
    cursor: pointer; }

.btn-brand {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none; }
  .btn-brand:hover {
    background: #666666; }

.btn-close {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 25px;
  background: #272d38;
  color: white;
  border: none;
  border-radius: 100%;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px !important; }

.btn-select {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none;
  background: white;
  color: #677f9E; }
  .btn-select:hover {
    background: #666666; }
  .btn-select:hover {
    background: #666666;
    color: white; }
  .btn-select.active {
    background: #677f9E;
    color: white; }

.rdw-editor-toolbar {
  border-radius: 0;
  border-bottom: none;
  background: #FAFAFA; }

.rdw-dropdown-wrapper, .rdw-option-wrapper {
  background: #FAFAFA; }

@keyframes infinite-shift-left {
  0% {
    background-position: -50000px; }
  100% {
    background-position: 0; } }

.center-form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px;
  max-width: 340px; }
  .center-form input {
    width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .center-form .Select-control {
    margin: 4px 0; }
  .center-form input[type=submit] {
    margin-top: 10px; }
  .center-form .alert {
    display: block;
    margin: 10px 0; }
  .center-form p {
    margin: 10px 0;
    text-align: center; }

.content-detail {
  min-height: 1000px; }
  .content-detail .summary .image {
    min-height: 500px; }
  .content-detail .summary .details-button {
    display: none; }
  .content-detail .summary .tags {
    padding-top: 10px;
    margin-top: 10px; }
  .content-detail .summary .images {
    display: block; }
    .content-detail .summary .images button {
      display: inline-block;
      background: none;
      border: none; }
  .content-detail .summary .btn-group {
    display: block;
    margin: -6px 0 0 -11px;
    font-size: 1.2em; }
  .content-detail .detail {
    color: #666666; }
    .content-detail .detail h4 {
      display: inline-block;
      margin-top: 20px;
      font-weight: bold; }
    .content-detail .detail a.brand-link {
      display: inline-block; }
    .content-detail .detail .docs ul {
      padding: 0; }
      .content-detail .detail .docs ul li {
        list-style: none; }
    .content-detail .detail .docs.literature-documents, .content-detail .detail .docs.condition-documents {
      margin-top: 15px;
      margin-left: 20px; }
    .content-detail .detail .literature-documents h4 {
      font-size: 1.1em; }
    .content-detail .detail .intended-for {
      margin-left: 20px; }
      .content-detail .detail .intended-for h4 {
        display: inline-block;
        margin-right: 15px;
        font-size: 1.1em; }
      .content-detail .detail .intended-for .open-select {
        font-size: 1.1em;
        color: #677f9E;
        cursor: pointer; }
      .content-detail .detail .intended-for .selector {
        position: relative;
        top: 15px;
        display: inline-block;
        min-width: 200px;
        z-index: 0; }
        .content-detail .detail .intended-for .selector.is-pseudo-focused .Select-control {
          border-color: #83a0c6;
          outline-color: #83a0c6;
          box-shadow: none; }
    .content-detail .detail .links {
      margin-left: 20px; }
    .content-detail .detail .collectors-note h4 {
      display: inline-block;
      margin-right: 5px; }
    .content-detail .detail .collectors-note .open-collectors-note {
      display: inline-block; }
    .content-detail .detail .collectors-note .rdw-editor-main {
      margin-bottom: 20px;
      padding: 0 10px;
      border: 1px solid rgba(0, 0, 0, 0.05); }
