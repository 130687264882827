.wrap {
  max-width: 1400px;
  margin: auto; }

.btn-group span {
  line-height: 31px;
  font-size: .8em; }

.brand-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #677f9E !important;
  font-size: .8em; }
  .brand-link:hover {
    text-decoration: none;
    color: #666666 !important;
    cursor: pointer; }

.btn-brand {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none; }
  .btn-brand:hover {
    background: #666666; }

.btn-close {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 25px;
  background: #272d38;
  color: white;
  border: none;
  border-radius: 100%;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px !important; }

.btn-select {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none;
  background: white;
  color: #677f9E; }
  .btn-select:hover {
    background: #666666; }
  .btn-select:hover {
    background: #666666;
    color: white; }
  .btn-select.active {
    background: #677f9E;
    color: white; }

.rdw-editor-toolbar {
  border-radius: 0;
  border-bottom: none;
  background: #FAFAFA; }

.rdw-dropdown-wrapper, .rdw-option-wrapper {
  background: #FAFAFA; }

@keyframes infinite-shift-left {
  0% {
    background-position: -50000px; }
  100% {
    background-position: 0; } }

.center-form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px;
  max-width: 340px; }
  .center-form input {
    width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .center-form .Select-control {
    margin: 4px 0; }
  .center-form input[type=submit] {
    margin-top: 10px; }
  .center-form .alert {
    display: block;
    margin: 10px 0; }
  .center-form p {
    margin: 10px 0;
    text-align: center; }

.main-nav > .wrap {
  overflow: visible; }

.main-nav .nav {
  overflow: visible;
  position: relative;
  z-index: 1; }
  .main-nav .nav a {
    margin: 0 15px;
    text-transform: uppercase;
    font-size: .5em;
    font-weight: 400;
    letter-spacing: 2px;
    color: white !important;
    line-height: 55px; }
    .main-nav .nav a:hover, .main-nav .nav a.active {
      text-decoration: none;
      color: white;
      opacity: .8;
      transition: opacity 100ms linear;
      cursor: pointer; }
  .main-nav .nav > .row {
    width: 100%;
    padding-left: 15px; }
    @media screen and (min-width: 768px) {
      .main-nav .nav > .row {
        justify-content: flex-end; } }
    .main-nav .nav > .row .tools-menu {
      position: relative;
      z-index: 2; }
      .main-nav .nav > .row .tools-menu .tools-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background: #f1f1f1;
        padding: 5px 15px;
        margin-top: 12px; }
        .main-nav .nav > .row .tools-menu .tools-dropdown a {
          display: block;
          margin: 0;
          text-align: left;
          color: #39536F !important; }
        @media screen and (min-width: 768px) {
          .main-nav .nav > .row .tools-menu .tools-dropdown {
            margin-top: 0; } }
    @media screen and (min-width: 768px) {
      .main-nav .nav > .row .static-menu {
        text-align: right; } }

@media screen and (min-width: 768px) {
  .main-nav .nav a {
    font-size: .8em; } }
