.wrap {
  max-width: 1400px;
  margin: auto; }

.btn-group span {
  line-height: 31px;
  font-size: .8em; }

.brand-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #677f9E !important;
  font-size: .8em; }
  .brand-link:hover {
    text-decoration: none;
    color: #666666 !important;
    cursor: pointer; }

.btn-brand {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none; }
  .btn-brand:hover {
    background: #666666; }

.btn-close {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 25px;
  background: #272d38;
  color: white;
  border: none;
  border-radius: 100%;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px !important; }

.btn-select {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none;
  background: white;
  color: #677f9E; }
  .btn-select:hover {
    background: #666666; }
  .btn-select:hover {
    background: #666666;
    color: white; }
  .btn-select.active {
    background: #677f9E;
    color: white; }

.rdw-editor-toolbar {
  border-radius: 0;
  border-bottom: none;
  background: #FAFAFA; }

.rdw-dropdown-wrapper, .rdw-option-wrapper {
  background: #FAFAFA; }

@keyframes infinite-shift-left {
  0% {
    background-position: -50000px; }
  100% {
    background-position: 0; } }

.center-form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px;
  max-width: 340px; }
  .center-form input {
    width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .center-form .Select-control {
    margin: 4px 0; }
  .center-form input[type=submit] {
    margin-top: 10px; }
  .center-form .alert {
    display: block;
    margin: 10px 0; }
  .center-form p {
    margin: 10px 0;
    text-align: center; }

.selector-nav {
  position: relative;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: .7em;
  align-self: center; }
  @media screen and (min-width: 768px) {
    .selector-nav {
      border-top: none;
      font-size: .8em; } }

.selector-dropdown {
  position: relative;
  border: 1px solid #39536F; }
  @media screen and (min-width: 768px) {
    .selector-dropdown {
      font-size: .8em;
      position: absolute;
      width: 100%; } }

.selectors label {
  background: none;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 50px; }
  .selectors label:hover, .selectors label.active {
    background: #272d38 !important; }
  @media screen and (min-width: 960px) {
    .selectors label {
      letter-spacing: 2px;
      margin-right: 15px; } }

.selectors .btn-group-toggle {
  justify-content: center; }
  @media screen and (min-width: 768px) {
    .selectors .btn-group-toggle {
      justify-content: normal; } }
  .selectors .btn-group-toggle label {
    font-size: .8em; }
    @media screen and (min-width: 768px) {
      .selectors .btn-group-toggle label {
        font-size: 1em;
        padding-top: 8px;
        padding-bottom: 8px; } }

.selection {
  position: relative; }
  .selection ul {
    padding: 0; }
    .selection ul li {
      display: inline-block;
      list-style: none;
      margin: 15px 15px 15px 0;
      padding: 10px 10px 10px 15px;
      background: #677f9E;
      color: white;
      font-size: .8em; }
      .selection ul li span.type {
        text-transform: capitalize; }
      .selection ul li a {
        display: inline-block;
        margin: 0 0 0 10px;
        width: 25px;
        background: #272d38;
        color: white;
        border: none;
        border-radius: 100%;
        height: 25px;
        cursor: pointer;
        text-align: center;
        line-height: 25px !important; }
        .selection ul li a span {
          display: block;
          line-height: 25px;
          width: 24px; }

.search-container {
  position: relative;
  height: 100%;
  text-align: center; }
  .search-container .search-icon {
    position: absolute;
    right: 0;
    bottom: -45px;
    padding: 15px;
    cursor: pointer;
    color: white; }
    @media screen and (min-width: 768px) {
      .search-container .search-icon {
        top: 50%;
        bottom: inherit;
        transform: translateY(-50%);
        color: #677f9E; } }
  .search-container input {
    display: inline-block;
    width: 100%;
    max-width: 320px;
    margin: 15px 0;
    padding: 5px; }
    .search-container input:focus {
      border-color: #83a0c6;
      outline-color: #83a0c6; }

.category-selector-container {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .category-selector-container button:focus {
    box-shadow: none; }
  .category-selector-container button small {
    display: block; }
  .category-selector-container .btn-submit {
    display: inline-block;
    margin: 20px auto; }
  @media screen and (min-width: 768px) {
    .category-selector-container {
      top: 50%;
      bottom: inherit;
      transform: translateY(-50%);
      color: #677f9E; } }
