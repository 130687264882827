.wrap {
  max-width: 1400px;
  margin: auto; }

.btn-group span {
  line-height: 31px;
  font-size: .8em; }

.brand-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #677f9E !important;
  font-size: .8em; }
  .brand-link:hover {
    text-decoration: none;
    color: #666666 !important;
    cursor: pointer; }

.btn-brand {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none; }
  .btn-brand:hover {
    background: #666666; }

.btn-close {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 25px;
  background: #272d38;
  color: white;
  border: none;
  border-radius: 100%;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px !important; }

.btn-select {
  background: #677f9E;
  color: white;
  border-radius: 0;
  outline: none;
  border: none;
  background: white;
  color: #677f9E; }
  .btn-select:hover {
    background: #666666; }
  .btn-select:hover {
    background: #666666;
    color: white; }
  .btn-select.active {
    background: #677f9E;
    color: white; }

.rdw-editor-toolbar {
  border-radius: 0;
  border-bottom: none;
  background: #FAFAFA; }

.rdw-dropdown-wrapper, .rdw-option-wrapper {
  background: #FAFAFA; }

@keyframes infinite-shift-left {
  0% {
    background-position: -50000px; }
  100% {
    background-position: 0; } }

.center-form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px;
  max-width: 340px; }
  .center-form input {
    width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .center-form .Select-control {
    margin: 4px 0; }
  .center-form input[type=submit] {
    margin-top: 10px; }
  .center-form .alert {
    display: block;
    margin: 10px 0; }
  .center-form p {
    margin: 10px 0;
    text-align: center; }

header {
  overflow: visible;
  background: white; }
  @media screen and (min-width: 768px) {
    header {
      position: fixed;
      z-index: 2;
      width: 100%; } }
  header a.home-link {
    display: inline-block; }
  header h1 {
    width: 100%;
    max-width: 290px;
    height: 39px;
    margin: 15px 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    background-image: url(/assets/theme/KC-Welcome-Logo.png);
    background-size: cover;
    cursor: pointer; }
    header h1.blue {
      background-image: url(/assets/theme/KC-Master-Logo.png); }
    @media screen and (min-width: 768px) {
      header h1 {
        margin-left: 0;
        margin-right: 0; } }
  header .search {
    position: relative;
    z-index: 4;
    text-align: right; }
  header .main-nav {
    background: #39536F;
    margin-bottom: 0; }
    header .main-nav .top-nav {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column-reverse; }
      header .main-nav .top-nav .selectors {
        flex: 1; }
      header .main-nav .top-nav .nav {
        flex: 1; }
      header .main-nav .top-nav .selector-dropdown {
        margin-top: 10px; }
    header .main-nav .bottom-nav {
      padding: 0 15px 15px; }
      header .main-nav .bottom-nav .dropdown-container {
        padding: 0;
        margin-bottom: 10px; }
        header .main-nav .bottom-nav .dropdown-container .selector-dropdown {
          width: 100%; }
      header .main-nav .bottom-nav .breadcrumbs {
        padding: 0; }
        header .main-nav .bottom-nav .breadcrumbs .back-btn {
          font-size: .7em;
          background: white;
          border-radius: 0;
          width: 100%;
          line-height: 23px; }
    @media screen and (min-width: 768px) {
      header .main-nav .top-nav {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0; }
      header .main-nav .bottom-nav {
        justify-content: space-between;
        padding: 10px 15px; }
        header .main-nav .bottom-nav .dropdown-container {
          margin-bottom: 0;
          padding: 0 30px 0 0; }
        header .main-nav .bottom-nav .breadcrumbs {
          align-self: flex-end; }
          header .main-nav .bottom-nav .breadcrumbs .back-btn {
            height: 36px; } }
    @media screen and (min-width: 960px) {
      header .main-nav .bottom-nav .breadcrumbs .back-btn {
        font-size: .9em; } }
